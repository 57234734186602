import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog';
import LeniQFeedbackForm from 'components/Forms/LeniQFeedbackForm';
import { ThumbsDown } from 'lucide-react';
import React, { useState } from 'react';

interface LeniQFeedbackModalProps {
  questionId: string;
  hasRated: boolean;
  setHasRated: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeniQFeedbackModal: React.FC<LeniQFeedbackModalProps> = ({ questionId, setHasRated, hasRated }) => {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={hasRated}>
        <ThumbsDown
          height={14}
          width={14}
          className={`cursor-pointer ${hasRated ? 'pointer-events-none text-gray-300' : 'text-gray-400'}`}
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <h3 className="text-gray-900 text-xl font-bold text-center">Let us know what went wrong</h3>
        </DialogHeader>
        <LeniQFeedbackForm questionId={questionId} setHasRated={setHasRated} handleToggleModal={setOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default LeniQFeedbackModal;
