import React from 'react';

interface LeniQQuestionProps {
  question: string;
}

const LeniQQuestionComponent: React.FC<LeniQQuestionProps> = ({ question }) => {
  return (
    <div className="flex flex-col w-full items-end  ">
      <div className="flex  p-2 px-4 flex-col items-end gap-2 rounded-xl bg-slate-100  border-primary-200  transition-all text-start ml-9 w-fit">
        {question}
      </div>
    </div>
  );
};

export default LeniQQuestionComponent;
