import { useUser } from 'contexts/UserContext';
import React, { useState } from 'react';
import { GptApi } from 'services/api/gpt';
import Typewriter from 'typewriter-effect';
import { Copy, ThumbsUp } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { User } from 'src/types/User/user';
import LeniQDataTable from '../LeniQDataTable';
import LeniQFeedbackModal from 'components/Modals/LeniQFeedbackModal';
import Image from 'next/image';

interface LeniQAnswerProps {
  question: string;
  answer: any;
  hasError: boolean;
  onRenderedAnimationCompleted: () => void;
}

const LeniQAnswerComponent: React.FC<LeniQAnswerProps> = ({ answer, hasError, onRenderedAnimationCompleted }) => {
  const { user: currentUser } = useUser();
  const [hasRated, setHasRated] = useState(false);

  const { toast } = useToast();

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast({
        description: 'Copied to clipboard!',
        variant: 'success',
        duration: 2000,
      });
    } catch (error) {
      toast({
        description: 'Oops! Something went wrong.',
        variant: 'error',
        duration: 2000,
      });
    }
  };

  const handlePositiveFeedback = async (
    questionId: string,
    user: User | null,
    setHasRated: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!user) return;
    try {
      await GptApi.giveFeedback({ success: true, questionId, user });
      toast({
        variant: 'success',
        description: 'Your feedback has been successfully submitted',
        duration: 3000,
      });
      setHasRated(true);
    } catch (error) {
      console.log(error);
      toast({
        variant: 'error',
        description: 'Something went wrong when giving your feedback - Please try again later',
        duration: 3000,
      });
      setHasRated(false);
    }
  };

  return (
    <div className="flex flex-row ">
      {answer && (answer.summary || answer?.tableData?.length > 0) && (
        <div
          className="flex items-start rounded-full mr-2 border-gray-300 border w-8 h-8  items-center justify-center my-4 mt-5 py-2   bg-blue-50 "
          style={{ flex: '0 0 32px' }}>
          <Image src="/static/icons/leniq-circle.svg" width={20} height={20} alt="Sage Plus Icon" />
        </div>
      )}

      <div className="flex flex-col items-start my-4 py-2 text-sm">
        {answer && !hasError && answer.summary && (
          <Typewriter
            options={{ delay: 10, cursor: '' }}
            onInit={typewriter => {
              typewriter
                .typeString(answer.summary as string)
                .callFunction(() => {
                  if (onRenderedAnimationCompleted) {
                    onRenderedAnimationCompleted();
                  }
                })
                .start();
            }}
          />
        )}
        {hasError && (
          <div className="bg-red-100 w-full p-2 flex flex-col items-start gap-2 rounded-xl transition-all text-start">
            Something went wrong. If the issue persist, please contact us through our help center.
          </div>
        )}
        {answer && !hasError && answer?.tableData?.length > 0 && <LeniQDataTable data={answer.tableData} />}
        {answer && (answer.summary || answer?.tableData?.length > 0) && (
          <div className="flex items-center gap-3 mt-2">
            <ThumbsUp
              height={14}
              width={14}
              className={`cursor-pointer ${hasRated ? 'pointer-events-none text-gray-300' : 'text-gray-400'}`}
              onClick={() => handlePositiveFeedback(answer?.questionId as string, currentUser, setHasRated)}
            />
            <LeniQFeedbackModal
              questionId={answer?.questionId as string}
              setHasRated={setHasRated}
              hasRated={hasRated}
            />
            <Copy
              height={14}
              width={14}
              className="text-gray-400 cursor-pointer"
              onClick={() => copyToClipboard(answer?.summary as string)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeniQAnswerComponent;
