import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';
import { User } from 'src/types/User/user';

export const getTableauDashboardCategories = (user: User | null) => {
  if (!user) return [];
  if (user.email === 'dev.prod.soulrooms@gmail.com') return TABLEAU_DASHBOARDS;
  if (user.dashboardViews?.includes('leasing') && user.dashboardViews?.includes('marketing')) {
    return TABLEAU_DASHBOARDS.filter(dashboard => user.dashboardViews?.includes(dashboard.pageId));
  }
  if (user.dashboardViews?.length === 1 && user.dashboardViews?.[0] === 'reporting') {
    return TABLEAU_DASHBOARDS.filter(dashboard => dashboard.pageId === 'reporting');
  }
  // handling revenue management dashboard
  return user.dashboardViews?.includes('reporting')
    ? TABLEAU_DASHBOARDS.filter(
        dashboard =>
          dashboard.pageId !== 'unit_turn' &&
          dashboard.pageId !== 'budgeted_vs_actual' &&
          dashboard.pageId !== 'marketing' &&
          dashboard.pageId !== 'leasing'
      )
    : TABLEAU_DASHBOARDS.filter(
        dashboard =>
          dashboard.pageId !== 'reporting' &&
          dashboard.pageId !== 'unit_turn' &&
          dashboard.pageId !== 'budgeted_vs_actual' &&
          dashboard.pageId !== 'marketing' &&
          dashboard.pageId !== 'leasing'
      );
};

export const isTableauDashboard = (item: object) => {
  return 'dashboardTabs' in item;
};
