import React, { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea.tsx';
import { LoadingSpinner } from '@/components/ui/spinner';
import { useUser } from 'contexts/UserContext';
import { GptApi } from 'services/api/gpt';
import { useToast } from '@/components/ui/use-toast';

const FeedbackOptions = [
  'I know the answer is incorrect',
  'I feel the answer is wrong',
  'This doesn’t answer my question',
  'The answer is hard to understand',
  'other',
] as const;

const FormEnum = z.enum(FeedbackOptions);

const formSchema = z.object({
  feedback: FormEnum,
  message: z.string().optional(),
});

interface LeniQFeedbackFormProps {
  questionId: string;
  setHasRated: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeniQFeedbackForm: React.FC<LeniQFeedbackFormProps> = ({ questionId, setHasRated, handleToggleModal }) => {
  const { user: currentUser } = useUser();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      feedback: 'I know the answer is incorrect',
      message: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    try {
      await GptApi.giveFeedback({
        success: false,
        reason: values.feedback,
        comment: values.message,
        questionId,
        user: currentUser,
      });
      toast({
        variant: 'success',
        description: 'Your feedback has been successfully submitted',
        duration: 3000,
      });
      setHasRated(true);
      handleToggleModal(false);
    } catch (error) {
      console.log(error);
      toast({
        variant: 'error',
        description: 'Something went wrong when giving your feedback - Please try again later',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="feedback"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Feedback</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Please select a role for this new user" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {FeedbackOptions.map(option => (
                      <SelectItem key={option} value={option}>
                        {option}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Message</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full" disabled={loading}>
          {!loading ? (
            'Submit'
          ) : (
            <div className="flex items-center justify-center">
              <LoadingSpinner className="mr-3" height={20} width={20} />
              <span>Submitting feedback...</span>
            </div>
          )}
        </Button>
      </form>
    </Form>
  );
};

export default LeniQFeedbackForm;
