import React, { useEffect, useState } from 'react';
import { jsonToCSV } from 'react-papaparse';
import {
  Table as OriTable,
  TableBody as OrigTableBody,
  TableCell as OrigTableCell,
  TableHead as OrigTableHead,
  TableHeader as OrigTableHeader,
  TableRow as OrigTableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';

interface LeniQDataTableProps {
  data: any[];
}

const Table = OriTable as React.FC<{ children: React.ReactNode }>;
const TableBody = OrigTableBody as React.FC<{ children: React.ReactNode }>;
const TableCell = OrigTableCell as React.FC<{ children: React.ReactNode }>;
const TableHead = OrigTableHead as React.FC<{ children: React.ReactNode }>;
const TableHeader = OrigTableHeader as React.FC<{ children: React.ReactNode }>;
const TableRow = OrigTableRow as React.FC<{ children: React.ReactNode }>;

const LeniQDataTable: React.FC<LeniQDataTableProps> = ({ data }) => {
  const [columns, setColumns] = useState<string[] | null>(null);

  useEffect(() => {
    if (data.length > 0) {
      setColumns(Object.keys(data[0]));
    }
  }, [data]);

  const generateCsvFile = () => {
    const element = document.createElement('a');
    const file = new Blob([jsonToCSV(data)], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = 'data-' + new Date().getTime() + '.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <div className="w-full overflow-auto" style={{ height: '400px', width: '280px' }}>
        <div
          className="p-4 flex justify-center border border-gray-300 rounded-lg overflow-auto"
          style={{ height: '350px' }}>
          <div className="w-full max-w-screen-lg mx-auto h-full ">
            <Table>
              <TableHeader>
                <TableRow>
                  {columns && columns.length > 0 && columns.map(key => <TableHead key={key}>{key}</TableHead>)}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data &&
                  data.length > 0 &&
                  data.map(d => (
                    <TableRow key={d.id}>
                      {columns?.map((c, i) => {
                        return <TableCell key={i}>{d[c]}</TableCell>;
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="py-2 flex  flex-row justify-end">
          <Button onClick={generateCsvFile}>Download</Button>
        </div>
      </div>
    </>
  );
};

export default LeniQDataTable;
